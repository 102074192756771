import React, { Component } from "react";
import Slider from "react-slick";
import TrophyIcon from "../images/trophy-icon.svg";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

export default function TestimonialSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // cssEase: "linear"
  };
  return (
    <div className="story-block-inner bg-white px-5 py-5 pt-0 shadow-lg rounded-3">
      <Slider {...settings}>
          <div className="story-block w-75" style={{margin : '0 auto'}}>
            <div className="row">
              <div className="col-md-12 text-center">
             <div className="testimonial-slider w-50 mb-5 bg-branding-1 p-2" style={{margin : '0 auto',borderBottomLeftRadius : 20,borderBottomRightRadius : 20}}>
             <h4 className="m-0 text-white">Guaranteed Winners</h4>
             </div>
                  <img className='w-25 rounded-circle mb-3' src={require('../images/AFC_TOTTON_LOGO_2023.png')} style={{margin : '0 auto'}} alt="jen"/>
             
              <div className="story-block-testimonial">
              
                  <p className="text-dark fst-italic" style={{fontSize: 24}}>“I have been a Burnley fan for over 70 years and it's such a worthwhile cause, every Burnley fan should get behind it”</p>
              </div>
              <div className="story-block-stats text-dark">
                  <p className="m-0"><strong style={{paddingRight : 60,fontSize: 24}}>Ronald B.</strong><strong className="position-relative" style={{fontSize: 24}}>Won shirt at Burnley vs QPR</strong></p>
              </div>
              </div>
            </div>
          </div>

          <div className="story-block w-75" style={{margin : '0 auto'}}>
            <div className="row">
              <div className="col-md-12 text-center">
             <div className="testimonial-slider w-50 mb-5 bg-branding-1 p-2" style={{margin : '0 auto',borderBottomLeftRadius : 20,borderBottomRightRadius : 20}}>
             <h4 className="m-0 text-white">Guaranteed Winners</h4>
             </div>
                  <img className='w-25 rounded-circle mb-3' src={require('../images/AFC_TOTTON_LOGO_2023.png')} style={{margin : '0 auto'}} alt="jen"/>
             
              <div className="story-block-testimonial">
              
                  <p className="text-dark fst-italic" style={{fontSize: 24}}>“I have been a Burnley fan for over 70 years and it's such a worthwhile cause, every Burnley fan should get behind it”</p>
              </div>
              <div className="story-block-stats text-dark">
                  <p className="m-0"><strong style={{paddingRight : 60,fontSize: 24}}>Ronald B.</strong><strong className="position-relative" style={{fontSize: 24}}>Won shirt at Burnley vs QPR</strong></p>
              </div>
              </div>
            </div>
          </div>


          <div className="story-block w-75" style={{margin : '0 auto'}}>
            <div className="row">
              <div className="col-md-12 text-center">
             <div className="testimonial-slider w-50 mb-5 bg-branding-1 p-2" style={{margin : '0 auto',borderBottomLeftRadius : 20,borderBottomRightRadius : 20}}>
             <h4 className="m-0 text-white">Guaranteed Winners</h4>
             </div>
                  <img className='w-25 rounded-circle mb-3' src={require('../images/AFC_TOTTON_LOGO_2023.png')} style={{margin : '0 auto'}} alt="jen"/>
             
              <div className="story-block-testimonial">
              
                  <p className="text-dark fst-italic" style={{fontSize: 24}}>“I have been a Burnley fan for over 70 years and it's such a worthwhile cause, every Burnley fan should get behind it”</p>
              </div>
              <div className="story-block-stats text-dark">
                  <p className="m-0"><strong style={{paddingRight : 60,fontSize: 24}}>Ronald B.</strong><strong className="position-relative" style={{fontSize: 24}}>Won shirt at Burnley vs QPR</strong></p>
              </div>
              </div>
            </div>
          </div>
          
          

      </Slider>
    </div>
  );
}
