import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoWhite from "../images/AFC_TOTTON_LOGO_2023.png";
import HeaderRightLogo from "../images/swindon.png";
// import HeaderRightLogo from '../images/nottingham-forest.png'
import TopBg from "../images/top-bg.png";
import LeftBg from "../images/left-bg.jpg";
import RightBg from "../images/right-bg.jpg";
import SignUp from "../images/playicon.png";
import Trophy from "../images/winicon.png";
import Charity from "../images/fundicon.png";
import Bg from "../images/bg.svg";
import Arrow from "../images/arrow.svg";
import LogoFoundationGray from "../images/AFC_TOTTON_LOGO_2023.png";
import CurevedDot from "../images/cureved-dot.svg";
import FooterIconAddress from "../images/footer-icon-address.svg";
import FooterIconEmail from "../images/footer-icon-email.svg";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter.svg";
import Instagram from "../images/instagram.svg";
import Visa from "../images/visa.svg";
import Mastercard from "../images/mastercard.svg";
import LogoFoundationWhite from "../images/logo-foundation-white.svg";
import HeaderLeftLogo from "../images/AFC_TOTTON_LOGO_2023.png";
import LogoRed from "../images/AFC_TOTTON_LOGO_2023.png";
import Cup from "../images/cup.svg";
import "../images/HeroBg.png";
import "../App.css";
import TestimonialSlider from "../components/testimonialSlider";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import apiClient from "../api.config";
import useDocumentTitle from "../components/useDocumentTitle";
import Moment from "moment";
import PrizeSlider from "../components/prizeSlider";

import { toast, ToastContainer } from "react-toastify";
import TicketImg1 from "../images/ticket-img1.svg";
import TicketImg2 from "../images/ticket-img2.svg";
import TicketImg3 from "../images/ticket-img3.svg";
import NextArrow from "../images/next-arrow.svg";
import loaderSpinner from "../images/Loader.gif";
import Cross from "../images/cross.svg";
import FreeEntryIcon from "../images/free-entry-icon.svg";
import StripeCheckout from "react-stripe-checkout";
import { Globalvar } from "../components/global";
import StripeForm from "../components/stripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Elements, useElements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Home() {
  useDocumentTitle("Home");
  const [scroll, setScroll] = useState(false);
  const [Sidebar, setSidebar] = useState(false);
  const [login, setLogin] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").loggedin : false);
  const [DrawData, setDrawData] = useState([]);

  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  function logout() {
    secureLocalStorage.clear();
    axios.delete(APIURL + "/auth/logout");
    setLogin(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
    axios.get(APIURL + "/sites/" + siteId + "/draws").then((res) => {
      let alldraw = res.data.content;
      let activeDraw = res.data.content.filter((item) => item.drawIsClosed != 1);
      let selectedDraw = {};
      if (activeDraw.length > 0) {
        selectedDraw = activeDraw[0];
      } else {
        selectedDraw = alldraw[0];
      }
      setDrawData(selectedDraw);
    });
  }, []);

  const [paymentDetails, setPaymentDetails] = useState(false);
  const [SubscriptionPlan, setSubscriptionPlan] = useState(true);
  const [Freemodal, setFreemodal] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(true);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [amount, setAmount] = useState(20);
  const [entries, setEntries] = useState(2);
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [consent3, setConsent3] = useState(false);
  const [userEmail, setEmail] = useState();
  const [fullName, setFullName] = useState("");
  const [userFirstName, setFirstName] = useState("");
  const [userLastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState(0);
  const [SendDate, setSendDate] = useState();
  const [gender, setGender] = useState(1);
  const [prevDrawData, setPrevDrawData] = useState("");
  const [winnerData, setWinnerData] = useState([]);
  const [winnerModal, setWinnerModal] = useState(false);
  const [oneShow, setOneShow] = useState(false);
  const [isoneoff, setIsoneoff] = useState(false);
  const [isStripe, setStripe] = useState(false);
  const [UserID, setUserId] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").userID : "");
  const userData = secureLocalStorage.getItem("LogObject");

  const navigate = useNavigate();
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  function hideModal() {
    setOneShow(false);
  }
  Globalvar.amount = 10;
  Globalvar.entries = 1;

  useEffect(() => {
    if (!userData) {
    } else {
      axios
        .get(APIURL + `/users/${userData.userID}/data`, {
          withCredentials: true,
          credentials: "same-origin",
        })
        .then((res) => {
          setEmail(res.data.content.userEmail);
          setFirstName(res.data.content.userFirstName);
          setLastName(res.data.content.userSurname);
          setFullName(res.data.content.userFirstName + " " + res.data.content.userSurname);
        });
    }
  }, []);

  useEffect(() => {
    axios
      .get(APIURL + `/draws/host/e69a2913-c7be-48ca-bc1c-e18975b65586`, {
        withCredentials: true,
        credentials: "same-origin",
      })
      .then((res) => {
        setPrevDrawData(
          res.data.content.drawData
            .filter((item) => item.drawIsClosed === 1 && moment(item.drawEndDate) < moment())
            .sort((a, b) => new Date(a.drawEndDate) - new Date(b.drawEndDate))
            .reverse()[0]
        );
      });
  }, []);

  // useEffect(() => {
  //   axios.get(APIURL + `/draws/winners/all/${prevDrawData.drawID}/1/1000`, {
  //     withCredentials: true,
  //     credentials: "same-origin"
  //   }
  //   ).then(res => {
  //     setWinnerData(res?.data?.content?.winners);
  //     console.log("winners", res?.data?.content?.winners);
  //   }
  //   );
  // }, [prevDrawData])

  const handleClick = (param, value, tickets) => {
    switch (param) {
      case "first":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(true);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "second":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(true);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "third":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "fourth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(true);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "fifth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(true);
        setIsActive6(false);
        return;
      case "sixth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(true);
        return;
      default:
        setEntries(5);
        setAmount(50);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
    }
  };

  console.log("DrawData", DrawData);

  function toPaymentDetails() {
    setSubscriptionPlan(false);
    setPaymentDetails(true);
  }

  // else if (userFirstName == '') {
  //   toast("Please Enter the First Name", { type: "error" });
  // }
  // else if (userLastName == '') {
  //   toast("Please Enter the Last Name", { type: "error" });
  // }

  async function checkConsents() {
    const fn = fullName.split(" ");

    // const checkConsents = () => {
    if (!consent3) {
      toast("Please read and accept Terms & Conditions", { type: "error" });
    }
    if (fullName === "") {
      toast.error(`Please Enter Your valid Full Name`);
    } else if (!fn[0] || fn[0] === "") {
      toast.error(`Please Enter Your First and last Name`);
    } else if (!fn[1] || fn[1] === "") {
      toast.error(`Please Enter Your last Name`);
    } else if (userEmail == "") {
      toast("Please Enter the Email Address", { type: "error" });
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userEmail)) {
      toast.error(`Invalid Email`);
    }
    // else if (dob === '') {
    //   toast.error(`Please Enter Your Date of birth`);
    // }
    // else if (dob.length < 10) {
    //   toast.error(`Please Enter Your valid Date of birth`);
    // }
    // else if(age <= 18) {
    //   console.log('age + ' + age);
    //   toast.error(`Sorry. Only users over the age of eighteen are allowed to enter this Prize Draw" then we're good to go live!`);
    // }
    else {
      setStripe(true);
      setOneShow(true);

      //ValidateEmail
      // const POSTCODER_KEY =
      //   process.env.POSTCODER_API_KEY || "PCWW5-FVLAY-K6FGB-KSTYM";
      // await axios
      //   .get(`https://ws.postcoder.com/pcw/${POSTCODER_KEY}/email/${userEmail}`)
      //   .then((res) => {
      //     console.log("res.data", res.data);
      //     if (res.data.valid) {
      //       setStripe(true);
      //       setOneShow(true);
      //     } else {
      //       toast.error(`Invalid Email`);
      //     }
      //   });
    }
  }

  let drawContent;
  let nextFixtureName = "";
  let leagueName = "";

  try {
    drawContent = JSON.parse(DrawData.drawContent);
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  if (drawContent) {
    // Access properties from the parsed JSON
    nextFixtureName = drawContent.nextfxitureName;
    leagueName = drawContent.leagueName;

    // Use nextFixtureName in your JSX or template
  } else {
    // Handle the case where parsing failed
    // This could be due to invalid JSON format
  }

  return (
    <>
      <header
        className={
          scroll
            ? "home transation-ease-in-out site-header font-face-sh py-2 position-sticky top-0 bg-branding-1 z-index-9"
            : "transation-ease-in-out site-header font-face-sh py-2"
        }
      >
        <div className="container">
          <div className="inner-header row d-flex align-items-center">
            <div className="logo col-sm-6 col-md-6 col-lg-6 col-6">
              <Link to={"/"}>
                <img className="start-0 not-responsive" src={scroll ? LogoWhite : LogoRed} alt="logo-white" style={{ width: 100 }} />
              </Link>
              <Link to={"/"}>
                <img className="start-0 only-responsive d-none" src={LogoWhite} alt="logo-white" style={{ width: 100 }} />
              </Link>
            </div>
            <div className="desktop-menu col-md-6 d-flex justify-content-end align-items-center">
              {login ? (
                <>
                  <DropdownButton align="end" title="Account" id="dropdown-menu-align-end" className="text-white transparent">
                    <Dropdown.Item eventKey="1" href="/account">
                      My Impact
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2" href="/account/prizes">
                      Prizes
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" href="/account/subscription">
                      Manage Subscription
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="6" href="/account/payment-method">
                      Manage Payment
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4" href="/account/details">
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="5" onClick={logout}>
                      Log out
                    </Dropdown.Item>
                  </DropdownButton>
                  <Link
                    to={"/subscription-plan"}
                    className="py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh"
                  >
                    Enter The Draw
                  </Link>
                </>
              ) : (
                <>
                  <div className="login-signup py-3 px-4 bg-white rounded-pill branding-2 border border-2 border-white ">
                    {/* <img src={require('../images/user.png')} alt="User"/> */}
                    <Link to={"/login"} className="branding-1 text-decoration-none fs-6 font-face-sh-bold" style={{ marginRight: 20 }}>
                      Login
                    </Link>
                    /
                    <Link to={"/signup"} className="branding-1 text-decoration-none fs-6 ms-3 lh-1 font-face-sh-bold">
                      Sign up
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className="col-sm-6 col-md-6 col-6 mobile-menu justify-content-end d-flex align-items-center d-none">
              <div className={Sidebar ? "MenuButton_rootActive" : "MenuButton_root"} onClick={() => setSidebar(!Sidebar)}>
                <div className={Sidebar ? "MenuButton_firstLineActive" : "MenuButton_firstLine"}></div>
                <div className={Sidebar ? "MenuButton_secondLineActive" : "MenuButton_secondLine"}></div>
                <div className={Sidebar ? "MenuButton_thirdLineActive" : "MenuButton_thirdLine"}></div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="mySidebar"
          className={
            Sidebar
              ? "open sidebar bg-light h-100 top-0 end-0 position-fixed d-flex flex-column ease-in-out overflow-hidden py-4 px-4"
              : "sidebar h-100 w-0 end-0 top-0 bg-light position-fixed pt-2 ease-in-out overflow-hidden"
          }
        >
          <div className="logo-red col-md-12">
            <Link to={"/"}>
              <img className="start-0" src={LogoRed} alt="logo-white" style={{ width: 100 }} />
            </Link>
          </div>
          <div className="mobile-menu-nav col-md-12 mt-5 d-flex flex-column">
            {login ? (
              <>
                <Link
                  to={"/subscription-plan"}
                  className="text-center mb-3 py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh"
                >
                  Enter The Draw
                </Link>
                <div className="fs-6 mb-1" style={{ color: "#999" }}>
                  <strong>Account</strong>
                </div>
                {/* <Link to={"/login"} className='black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover'>Login</Link>
          <Link to={"/signup"} className='py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1'>Join Now</Link> */}
                <Link to={"/account"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  My Impact
                </Link>
                <Link to={"/account/prizes"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Prizes
                </Link>
                <Link to={"/account/subscription"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Subscription
                </Link>
                <Link to={"/account/payment-method"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Payment
                </Link>
                <Link to={"/account/details"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Settings
                </Link>
                <hr />
                <Link onClick={logout} to={"/"} className="black-clr text-decoration-none fs-5 branding-1-hover">
                  Log Out
                </Link>
              </>
            ) : (
              <>
                <Link to={"/login"} className="black-clr text-decoration-none fs-5 font-face-sh branding-1-hover">
                  Login
                </Link>
                <Link
                  to={"/signup"}
                  className="py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1"
                >
                  Join Now
                </Link>
              </>
            )}
          </div>

          <div className="col-sm-12 col-md-12 col-lg-3 mt-auto ">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2 font-face-sh-bold">Follow us</p>
                <div className="d-flex">
                  <a className="branding-2 text-decoration-none" href="https://www.facebook.com/afctottonofficial" target="_blank">
                    <img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} />
                  </a>
                  <a className="branding-2 text-decoration-none" href="https://twitter.com/AFCTotton" target="_blank">
                    <img className="me-3" src={Twitter} alt="Twitter" style={{ width: 24 }} />
                  </a>
                  <a className="branding-2 text-decoration-none" href="https://www.instagram.com/afctottonofficial/" target="_blank">
                    <img className="me-3" src={Instagram} alt="Instagram" style={{ width: 24 }} />
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2" style={{ fontSize: 14 }}>
                  AFC Totton 200 Club is operated by{" "}
                  <a className="text-decoration-none branding-1 branding-1-hover" href="https://playfundwin.com/" target="_blank">
                    Play Fund Win
                  </a>
                </p>
                <div className="d-flex font-face-sh-bold" style={{ fontSize: 14 }}>
                  <Link className="branding-1 text-decoration-none pe-5 branding-1-hover" to={"/terms-conditions"}>
                    Terms & Conditions
                  </Link>
                  <Link className="branding-1 text-decoration-none branding-1-hover" to={"/privacy-policy"}>
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="hero-section herosec-img font-face-sh py-sm-" style={{ marginTop: "-165px", padding: "170px 0 20px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-12 text-center" style={{ paddingTop: "390px" }}>
              <h1 className="text-white title-managed position-relative lh-4 w-auto mt-4 mb-0 display-4 font-face-sh-bold text-uppercase" style={{ zIndex: 9 }}>
                It’s a win win!
              </h1>
              <h3 className="w-100 fs-5 branding-2 bg-white lh-2 p-3 fw-normal mr-0">Support AFC Totton 200 Club and be in with a chance to win AFC Totton 200 Club Prizes.</h3>
              {/* <Link to={'/signup'} className="btn text-uppercase bg-branding-green border-0 w-50 rounded-pill py-3 mt-5 lh-1 desktop-btn text-light ">Join Now</Link> */}
            </div>
            {/* <div className='col-sm-12 col-md-5 col-lg-5 hero-right-block d-flex justify-content-end'>
            <div className='match-card w-100 ms-2'>
              <div className='matchcard-header px-4 py-3 d-flex align-items-center justify-content-between bg-branding-1'>
                <p className='text-white fs-5 m-0'>In support of</p>
                <img className='start-0' style={{width: 178}} src={LogoFoundationWhite} alt="LogoFoundationWhite" />
              </div>
              <div className='matchcard-logosblock bg-white text-center p-5' style={{borderBottomLeftRadius: 90}}>
                <p className='branding-2 fs-4'>Winners next announced:</p>
                <div className='d-flex align-items-center justify-content-between py-5 pb-5'>
                  <img className='start-0' style={{width: 100}} src={Favicon} alt="Favicon" />
                  <h2 className="branding-2 lh-1 display-3 font-face-sh-bold">VS</h2>
                  <img className='start-0' style={{width: 110}} src={vsLogo} alt="vsLogo" />
                </div>
                <p className='branding-2 fs-4 pt-2 m-0'>{Moment(DrawData?.drawEndDate).format('LLL')}</p>
              </div>
            </div>
          </div> */}
            {/* <div className='prizes-section-data text-center d-flex border-white border-bottom rounded-top' style={{justifyContent : 'center'}}>
            <div className='prizes-section-subdata'>
              <h3 className="w-auto fs-5 lh-2 p-3 fw-normal mb-0 mr-0 mt-5 branding-1 bg-white" style={{borderTopLeftRadius : 20,borderTopRightRadius : 20}}>WIN PRIZES EVERY MONTH</h3>
            </div>
          </div> */}

            {/* <div className='mobile-bottom d-none'>
              <Link to={"/signup"} className="btn bg-branding-green text-uppercase border-0 w-50 rounded-pill py-3 mt-5 lh-1 text-white">Join Now</Link>
            </div> */}
          </div>
        </div>
        <div className="prizes-sliders py-5 pt-0">
          <div className="container">
            {/* <img className="" src={require('../images/mascot.jpg')} width="100%" height="600px" style={{objectFit : 'cover', objectPosition : '10%'}}/> */}
            <div className="prizes-section-data mb-3 text-center d-flex border-white border-bottom rounded-top" style={{ justifyContent: "center" }}>
              <div className="prizes-section-subdata">
                <h3 className="w-auto fs-5 lh-2 p-3 fw-normal mb-0 mr-0 mt-5 branding-1 bg-white" style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                  WIN AMAZING PRIZES
                </h3>
              </div>
            </div>
            <PrizeSlider />
            {/* <h3 className="w-100 fs-5 text-center text-white lh-2 p-3 fw-normal mb-2 mr-0">Winners announced will be before the fixture suggested Wednesday 19th April draw.</h3> */}
            {/* <div className='join-now-button text-center'><Link to={'/signup'} className="btn text-uppercase bg-white border-0 w-auto rounded-pill py-3 mt-1 lh-1 desktop-btn branding-1 ">Join Now</Link></div>
             */}
          </div>
        </div>
      </section>

      {/* {DrawData ?  */}
      <section></section>
      {/* : ''} */}

      <section className="ticket-data bg-branding-btn-sec">
        <div className="container">
          <div className="ticket-section pb-5">
            <div className="py-1 d-flex align-items-center justify-content-center mb-3">
              <img src={require("../images/playicon.png")} />
              <h2 className="mt-2 mx-3">
                <span className="position-relative display-5 branding-2 font-face-sh-bold">ENTER THE DRAW</span>
              </h2>
            </div>
            {/* <p className='fs-5 text-center text-white'>How many tickets would you like to buy? £5 per ticket</p> */}

            {!paymentDetails && SubscriptionPlan ? (
              <section className="subscription-plan font-face-sh">
                <div className="subscription-plan-container mx-auto">
                  {/* <svg viewBox="0 0 1425 358" xmlns="http://www.w3.org/2000/svg" className="StepSubscriptionPlan_topBackground position-fixed top-0 start-0" style={{ width: "100vw", height: "25.12281vw" }}><path d="M1425 .84v207.845c0 197.772-1425 200.397-1425 0V.42l1425 .42z" fill="#382766" fillRule="nonzero" /></svg> */}
                  <div
                    className={Freemodal ? "steps-subscription-plan position-relative pt-0 d-none" : "steps-subscription-plan position-relative pt-0 d-block"}
                    style={{ zIndex: 999 }}
                  >
                    {/* <h2 className='step-subscription-plan-title text-light font-face-sh-bold display-6 text-center mb-3'>Pick your monthly plan</h2> */}
                    {/* <div className='step-subscription-plan-description'>
                      <h3 className='step-subscription-plan-description_title text-light font-face-sh-bold fs-5 text-center mb-0'>How many entries do you want for each draw?</h3>
                      <p className='step-subscription-plan-description_text text-light text-center'>You can cancel or change your plan at any time.</p>
                  </div> */}
                    <div className="step-subscription-plan-selector pt-4">
                      <ul className="d-flex unstyled list-unstyled gap-3">
                        <li className={isActive1 ? "active" : ""} onClick={() => handleClick("first", 10, 1)}>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #24284d", paddingBottom: 15 }}>
                              1 ticket
                            </h4>
                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">10</span>
                            </div>
                          </div>
                        </li>
                        <li className={isActive2 ? "active" : ""} onClick={() => handleClick("second", 20, 2)}>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #24284d", paddingBottom: 15 }}>
                              2 tickets
                            </h4>

                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">20</span>
                            </div>
                          </div>
                        </li>
                        <li className={isActive3 ? "position-relative active" : "position-relative"} onClick={() => handleClick("third", 50, 5)}>
                          <div
                            className="subscription-plan-emphasis branding-1 d-inline-block lh-normal position-absolute start-0 end-0 m-auto bg-branding-2"
                            style={{
                              fontSize: 14,
                              padding: "7px 6px 7px 7px",
                              width: 150,
                              top: -40,
                              zIndex: 999,
                              textAlign: "center",
                              borderTopLeftRadius: 20,
                              borderTopRightRadius: 20,
                            }}
                          >
                            Popular
                          </div>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #24284d", paddingBottom: 15 }}>
                              5 tickets
                            </h4>

                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">50</span>
                            </div>
                          </div>
                        </li>
                        <li className={isActive4 ? "active" : ""} onClick={() => handleClick("fourth", 100, 10)}>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #24284d", paddingBottom: 15 }}>
                              10 tickets
                            </h4>

                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">100</span>
                            </div>
                          </div>
                        </li>
                        {/* <li className={isActive5 ? 'active' : ''} onClick={() => handleClick('fifth', 100, 20)}>
                              <div className='ticket-title-sec px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                              <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#302d51', borderBottom : '2px solid #24284d', paddingBottom : 15  }}>20 Tickets</h4>
                                 

                                  <div className='price text-center posiion-relative'>
                                    <span className='display-6'>£</span><span  className='display-4'>100</span>
                                  </div>
                                 
                              </div>
                              
                          </li>
                          <li className={isActive6 ? 'active' : ''} onClick={() => handleClick('sixth', 250, 50)}>
                              <div className='ticket-title-sec px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                              <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#302d51', borderBottom : '2px solid #24284d', paddingBottom : 15  }}>50 Tickets</h4>
                                 

                                  <div className='price text-center posiion-relative'>
                                    <span className='display-6'>£</span><span  className='display-4'>250</span>
                                  </div>
                                 
                              </div>
                              
                          </li> */}
                        {/* <li onClick={() => setFreemodal(!Freemodal)}>
                              <div className='ticket-title-sec px-3 py-5 position-relative other' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                              <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#fff', borderBottom : '2px solid #24284d', paddingBottom : 15  }}>5 tickets</h4>
                                
                                  <div className='price text-center posiion-relative'>
                                    <span className='display-6 text-white'>£</span><span  className='display-2 text-white'>5</span>
                                  </div>
                              </div>
                            
                          </li> */}
                      </ul>
                    </div>
                    <div className="subscription-plan-selector-amountarea bg-white p-3 mt-4" style={{ borderRadius: 6 }}>
                      <div className="row  align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="subscription-plan-price d-flex justify-content-center">
                            <div className="subscription-plan-price_currency branding-1 fs-2 font-face-sh-bold mt-4 me-1">£</div>
                            <div className="subscription-plan-price_amount branding-1 display-3 font-face-sh-bold">{amount}</div>
                            <div className="subscription-plan-price_term text-black lh-1 mt-3 ms-1" style={{ fontSize: 12 }}>
                              {isoneoff ? (
                                ""
                              ) : (
                                <>
                                  per
                                  <br />
                                  month
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="subscription-plan-selector-entries d-flex align-items-center">
                            <div className="draw-entries-icon position-relative" style={{ backgroundColor: "#f3f2fb", width: 52, height: 52, borderRadius: "50%" }}></div>
                            <ul className="unstyled list-unstyled ul-tick ms-3 mb-0" style={{ fontSize: 12 }}>
                              <li>Auto-entry into every home draw</li>
                              <li>Regular charitable support</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-check form-switch d-flex justify-content-center pt-5">
                      <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input me-2" type="checkbox" id="flexSwitchCheckDefault" />
                      <label className="form-check-label text-white" htmlFor="flexSwitchCheckDefault">
                        {!isoneoff ? "Tick to make a one-off entry into the next draw" : "untick to make a Subscription entry into the next draw"}
                      </label>
                    </div>
                    <div className="terms-cond mt-5 text-center">
                      <p className="m-0 text-white">
                        Alternative entry routes available,{" "}
                        <Link className="text-decoration-none branding-1 branding-1-hover" to={"/terms-conditions"}>
                          click here
                        </Link>{" "}
                        for terms.
                      </p>
                    </div>
                    <div className="step-subscription-button text-center mb-5 d-flex justify-content-center">
                      <button
                        onClick={() => toPaymentDetails(true)}
                        className="btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                        style={{ width: 400, height: 48 }}
                      >
                        <span>Enter Draw</span>
                        <img className="position-absolute top-0 bottom-0 my-auto" src={NextArrow} style={{ height: 40, right: 4 }} />
                      </button>
                    </div>
                  </div>
                  <div className={Freemodal ? "free-entries-modal d-block position-relative" : "free-entries-modal d-none"}>
                    <div className="free-entry-modal_title_block mb-5 pb-5">
                      <img
                        className="cross bg-white rounded-3 p-1 position-absolute end-0 top-0"
                        src={Cross}
                        style={{ width: 56, boxShadow: "0 4px 16px rgb(20 19 38 / 30%)", cursor: "pointer" }}
                        onClick={() => setFreemodal(!Freemodal)}
                      />
                      <h2 className="free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5">Free Prize Draw</h2>
                      <p className="free-entry-modal_text text-light text-center fs-5 mx-auto mt-5" style={{ maxWidth: 375 }}>
                        While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.
                      </p>
                    </div>
                    <div className="free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex" style={{ maxWidth: 375 }}>
                      <div className="free-entry-icon">
                        <img src={FreeEntryIcon} style={{ width: 24 }} />
                      </div>
                      <div className="free-entry-description ps-4 fs-14 lh-1">
                        <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                        <p>
                          * First Name
                          <br />
                          * Last Name
                          <br />* Email
                        </p>
                        <p>
                          to: PFW Holdings Ltd
                          <br />
                          Play Fund Win Office #300,
                          <br />4 Blenheim Court,
                          <br />
                          Peppercorn Close,
                          <br />
                          Peterborough,
                          <br />
                          PE1 2DU
                          <br />
                          <br />
                          You will be entered into the prize draws for the month in which we receive your post entries.
                          <br />
                          <br />
                          Note: This will not result in any contributions to the Foundation
                        </p>
                        <div className="emoji-icon text-center pe-5 mt-4 pt-2">
                          <img className="mx-auto" src={require("../images/emoji_icon.png")} style={{ width: 66 }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : paymentDetails ? (
              <section className="personal-details font-face-sh py-5">
                <div className="subscription-plan-container mx-auto" style={{ maxWidth: 780 }}>
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12 step-user-details pe-5">
                      <div
                        className="step-plan_step branding-1 rounded-pill text-center mx-auto"
                        style={{ backgroundColor: "#f7f7fd", width: 112, height: 45, lineHeight: "45px" }}
                      >
                        <strong>Step 2 of 2</strong>
                      </div>
                      {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                      <p className="text-center" style={{ color: "#808080", fontSize: "18px" }}>
                        {isoneoff ? "" : "We’ll take your amount "}
                      </p>
                      <form method="post">
                        {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                        {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                        {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                        <div className="form-checkbox">
                          <ul className="checkbox-list list-unstyled">
                            <li className="checkbox-item mb-4">
                              <label className="input-checkbox d-flex">
                                <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                                <span className="input-checkbox_check"></span>
                                <span className="input-checkbox-label">Yes, I’d like to receive updates about NABS via email.</span>
                              </label>
                            </li>
                            <li className="checkbox-item">
                              <label className="input-checkbox d-flex">
                                <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                                <span className="input-checkbox_check"></span>
                                <span className="input-checkbox-label">Yes, NABS Foundation can contact me about their work via email.</span>
                              </label>
                            </li>
                            <li className="checkbox-item">
                              <label
                                className="step-payment-details_consent_description mt-5 input-checkbox d-flex"
                                style={{ backgroundColor: "rgb(198,29,35,.1)", color: "#302d51", padding: "14px 18px", borderRadius: 20, fontSize: 13 }}
                              >
                                <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                                <span className="input-checkbox_check"></span>
                                <span>
                                  By clicking on Confirm you agree to NABS Draw{" "}
                                  <Link to={"/terms-conditions"} style={{ color: "#302d51" }}>
                                    Terms & Conditions of Use
                                  </Link>
                                  ,{" "}
                                  <Link to={"/privacy-policy"} style={{ color: "#302d51" }}>
                                    Privacy Policy
                                  </Link>{" "}
                                  and that you <strong>are at least 18 years old.</strong>
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </form>
                      {!consent3 ? (
                        <button
                          onClick={checkConsents}
                          className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                          style={{ width: "100%", height: 48 }}
                        >
                          Pay now
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setStripe(true);
                            setOneShow(true);
                          }}
                          className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                          style={{ width: "100%", height: 48 }}
                        >
                          Pay now
                        </button>
                      )}
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0">
                      <div className="user-details-info bg-branding-1 rounded-4" style={{ padding: "35px 35px 19px", boxShadow: "0 15px 50px -30px #000000" }}>
                        <h3 className="text-light fs-5 mb-4">Summary</h3>
                        <div className="summary-info-impact pb-3">
                          <div className="summary-info-impact_title text-light d-flex align-items-center" style={{ fontSize: 16 }}>
                            1x VIP Prizes Up For Grabs!
                          </div>
                          {/* <div className='summary-info-impact_period lh-1' style={{ color: 'rgb(255,255,255,0.7)', paddingLeft: 35 }}>every draw</div> */}
                        </div>
                        <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                        <div className="summary-info-price d-flex align-items-center pb-2">
                          <span className="summary-info-price_amount text-light display-4">£ {amount}</span>
                          <span className="summary-info-price_period ps-3" style={{ color: "rgb(255,255,255,0.7)" }}>
                            {isoneoff ? "" : "per month"}
                          </span>
                        </div>
                        <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                        <p className="summary-info-upgrade-text m-0" style={{ color: "rgb(255,255,255,0.7)" }}>
                          Upgrade or cancel anytime
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
      <section className="fixtures bg-branding-2 py-5">
        <div className="container">
          <div className="team-fixtures bg-white rounded-4 pt-3 px-4 pb-3">
            <h4 className="text-center font-face-sh-bold branding-1">{leagueName}</h4>
            <div className="row align-items-center">
              <div className="col-md-2 text-center">
                <img className="start-0" style={{ width: "100%" }} src={HeaderLeftLogo} alt="Favicon" />
              </div>
              <div className="col-md-8 text-center">
                {/* <h4 className='branding-2'>Winners next announced:</h4> */}

                <div className="row">
                  <div className="col-md-5 text-right">
                    <h2 className="branding-2 lh-1 font-face-sh-bold text-right">
                      AFC Totton
                      <br />
                    </h2>
                  </div>
                  <div className="col-md-2 text-center">
                    <h2 className="branding-1 lh-1 font-face-sh-bold">VS</h2>
                  </div>
                  <div className="col-md-5 text-left">
                    <h2 className=" lh-1 font-face-sh-bold text-left branding-2 ">{nextFixtureName}</h2>
                  </div>
                </div>
                {/* <p className='branding-1 fs-4 pt-2 m-0'>{Moment(DrawData?.drawEndDate).format('LLL')}</p> */}
                {/* <p className='branding-1 pt-2 m-0'>Winners announced will be before the fixture suggested Wednesday 19th April draw.</p> */}
              </div>
              <div className="col-md-2 text-center">
                {/* <img className='start-0' style={{width: "100%"}} src={HeaderRightLogo} alt="vsLogo" /> */}
                <img
                  className="start-0"
                  style={{ width: "100%" }}
                  src={DrawData.drawContent ? (JSON.parse(DrawData.drawContent).vsLogo ? JSON.parse(DrawData.drawContent).vsLogo : HeaderRightLogo) : loaderSpinner}
                  alt="vs Logo"
                />
              </div>
            </div>
            {/* <div className='text-center'>
              <span className='text-white fs-6 text-center bg-branding-1 px-3 pt-2 pb-2 d-inline-block fw-mediumbold' onClick={() => { setWinnerModal(true) }} style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, cursor: 'pointer' }}><img src={Cup} /> View Previous Winners</span>
            </div> */}
          </div>
        </div>
      </section>

      <Modal show={winnerModal} onHide={() => setWinnerModal(false)} size="xl" centered>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <section className="previous-winners_section font-face-sh mb-3">
            <div className="container">
              <div className="previous-winners_title text-center">
                {/* <h4 className='text-white bg-branding-1 fs-5 text-center py-1 px-3 rounded-pill d-inline-block font-face-sh-bold'>{Moment(prevDrawData.drawStartDate).format('MMMM DD, YYYY')} - {Moment(prevDrawData.drawEndDate).format('MMMM DD, YYYY')}</h4> */}
                <h2 className="display-6 text-uppercase text-center border-bottom pb-2 mb-3 font-face-sh-bold black-clr">Previous Winners</h2>
              </div>

              <div className="text-center">
                {winnerData.length ? (
                  <div className="row justify-content-center">
                    {winnerData.length === 0 ? (
                      <span className="fs-5 text-center">Winners Not Announced Yet !</span>
                    ) : (
                      winnerData.map((item) => {
                        return (
                          <div className="col-md-4 winner-col px-2">
                            <div className="winner-col-inner rounded-3 px-3 py-1 mb-3" style={{ backgroundColor: "#f8f8f8" }}>
                              <div className="d-flex align-items-center">
                                <div className="win-icon">
                                  <img src={require("../images/winner-icon.png")} alt="winner" style={{ width: 35, marginTop: "-5px" }} />
                                </div>
                                <div className="win-info ms-2 text-start">
                                  <h5 className="mb-0 fs-6">
                                    <strong>
                                      {item.drawUserFirstName} {item.drawUserSurname}
                                    </strong>
                                  </h5>
                                  <h5 className="mb-0 mt-1" style={{ fontSize: 14 }}>
                                    {item.drawPrizeName}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                ) : (
                  <img src={loaderSpinner} style={{ width: 60, margin: "0 auto" }} />
                )}
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

      {/* <section className='stories-section-title font-face-sh '> */}
      {/* <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='black-clr display-4 text-uppercase text-center mt-md-5 font-face-sh-bold' style={{ paddingBottom: 170 }}>Guaranteed Half-time Winners</h2>
            </div>
          </div>
        </div> */}
      {/* <div className='stories-section bg-branding-2'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='stories-container p-3 mx-auto mt-3 position-relative' style={{ width: '100%', borderRadius: 20, marginTop: -125 }}>
                <img className='mx-auto' src={require('../images/thankyou.jpg')} style={{ width: '100%' }} /> */}
      {/* <TestimonialSlider /> */}
      {/* <img className='mx-auto position-absolute start-0 end-0 curve-dot' src={CurevedDot} alt="CurevedDot" style={{ width: 92, bottom: -23 }} /> */}
      {/* <div className='row'>
                  <div className='col-md-6 col-sm-6 col-lg-6 prize-list'>
                    <h4>VIP Mascots – 2 places, 1st & 2nd Prize</h4>
                    <ul className='prize-data'>
                      <li>Two match tickets (one for the mascot and one for an accompanying adult)</li>
                      <li>Full 2022/23 home kit</li>
                      <li>Feature in the programme and a physical copy to keep</li>
                      <li>Feature on the big screen</li>
                      <li>Chance to meet the players and manager pre-match and get something signed (subject to manager’s permission)</li>
                      <li>A group pitchside photo with the other mascots</li>
                      <li>The opportunity to walk out with a player of their choice</li>
                      <li>A photo in the centre circle with the team captains and match officials</li>
                    </ul>
                  </div>
                  <div className='col-md-6 col-sm-6 col-lg-6 prize-list'>
                    <h4>Player Experience Mascots – 9 places, runners up</h4>
                      <ul className='prize-data'>
                        <li>Two match tickets (one for the mascot and one for an accompanying adult)</li>
                        <li>Feature in the programme and a physical copy to keep</li>
                        <li>Feature on the big screen</li>
                        <li>A group pitchside photo with the other mascots</li>
                        <li>The opportunity to walk out with a first team player</li>
                      </ul>
                  </div>
                </div> */}
      {/* </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* </section> */}

      {/* <section className='prizes-section font-face-sh py-5 position-relative'>
        <div className='container'>
          <div className='row'>
            <div className='prizes-section_wrapper col-12 py-md-5' style={{ marginTop: '50px' }}>
              <h2 className='text-white display-4 text-uppercase text-center mt-md-5 font-face-sh-bold'>Win Prizes Every Home Game</h2>
              <p className='text-white fs-5 text-center' style={{ marginBottom: '125px' }}>New cash & club prizes to be won every home game. Winners informed via email.</p>
              <div className='prizes-section_container mx-auto bg-branding-1 pb-3' style={{ width: 1030, borderRadius: 24 }}>
                <div className='prizes-section_blockcontainer_sec d-flex' style={{ borderRadius: 10 }}>
                  <div className='prizes-section_blockcontainer p-2 bg-white mx-md-5 w-100' style={{ borderRadius: 10, marginTop: '-90px' }}>
                    <div className='prizes-section_block text-center bg-position pt-4 pb-4' style={{ backgroundImage: `url(${TopBg})`, borderRadius: 10 }}>
                      <h2 className='text-white display-5 lh-1 m-0 font-face-sh-bold'>Signed AFC Totton 200 Club MOTM replica home shirt.</h2>
                      <h3 className='text-white display-5 m-0 font-face-sh-bold'></h3>
                    </div>
                  </div>
                </div>
                <div className='prizes-section_cupholder text-center w-100 position-relative d-flex align-items-center justify-content-center'>
                  <div className='prizes-section_cupholder_inner d-flex align-items-center justify-content-center position-absolute start-0 end-0 bottom-0' style={{ top: 26 }}>
                    <div className='shadow-lg prizes-section_cup bg-branding-2 rounded-pill mx-3 d-flex justify-content-center' style={{ width: 48, height: 48, marginTop: -11 }}>
                      <img className='' src={Cup} alt="Cup" style={{ width: 24 }} />
                    </div>
                    <div className='shadow-lg prizes-section_cup bg-branding-2 rounded-pill mx-3 d-flex justify-content-center' style={{ width: 64, height: 64 }}>
                      <img className='' src={Cup} alt="Cup" style={{ width: 32 }} />
                    </div>
                    <div className='shadow-lg prizes-section_cup bg-branding-2 rounded-pill mx-3 d-flex justify-content-center' style={{ width: 48, height: 48, marginTop: -11 }}>
                      <img className='' src={Cup} alt="Cup" style={{ width: 24 }} />
                    </div>
                  </div>
                </div>
                <div className='prizes-section-prizes_container d-flex align-items-center text-center mx-5 mt-4'>
                  <div className='col-md-6 pe-2' style={{ borderRadius: 10 }}>
                    <div className='prizes-section_prizes bg-white p-2' style={{ borderRadius: 10 }}>
                      <div className='prizes-section_prizes_bg branding-1-light-bg py-4 bg-position' style={{ backgroundImage: `url(${RightBg})`, borderRadius: 10 }}>
                        <h3 className='text-white fs-3 text-uppercase m-0  px-4 font-face-sh-bold'>An item of AFC Totton 200 Club memorabilia.</h3>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 ps-2' style={{ borderRadius: 10 }}>
                    <div className='prizes-section_prizes bg-white p-2' style={{ borderRadius: 10 }}>
                      <div className='prizes-section_prizes_bg branding-1-light-bg py-5 bg-position' style={{ backgroundImage: `url(${LeftBg})`, borderRadius: 10 }}>
                        <h3 className='text-white fs-3 text-uppercase m-0  px-2 font-face-sh-bold'>£100 cash prize. </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='prizes-section-prizes_container d-flex align-items-center text-center mx-5 mt-4 d-none'>
                  <div className='col-md-6 pe-2' style={{ borderRadius: 10 }}>
                    <div className='prizes-section_prizes bg-white p-2' style={{ borderRadius: 10 }}>
                      <div className='prizes-section_prizes_bg branding-1-light-bg py-4 bg-position' style={{ backgroundImage: `url(${RightBg})`, borderRadius: 10 }}>
                        <h3 className='text-white fs-3 text-uppercase m-0  px-4 font-face-sh-bold'>Bobby Decordova-Reid personalised Football Boots</h3>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 ps-2' style={{ borderRadius: 10 }}>
                    <div className='prizes-section_prizes bg-white p-2' style={{ borderRadius: 10 }}>
                      <div className='prizes-section_prizes_bg branding-1-light-bg py-5 bg-position' style={{ backgroundImage: `url(${LeftBg})`, borderRadius: 10 }}>
                        <h3 className='text-white fs-3 text-uppercase m-0  px-2 font-face-sh-bold'>£100 Cash Prize</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section
        className="mission-section font-face-sh"
        style={{
          paddingTop: 40,
          backgroundImage: `linear-gradient(#49a2f1,#24284d70),url(${require("../images/afc-cham-snows.jpg")})`,
          zIndex: 99,
          position: "relative",
          backgroundSize: "contain",
          backgroundPosition: "top",
        }}
      >
        <div className="container">
          {/* <div className='row mb-5'>
                  <div className='col-md-6 col-sm-6 col-lg-6 prize-list'>
                    <h4 className='mb-4'><span className='title-prizes'>VIP Mascots</span> – 2 places, 1st & 2nd Prize</h4>
                    <ul className='prize-data'>
                      <li>Two match tickets (one for the mascot and one for an accompanying adult)</li>
                      <li>Full 2022/23 home kit</li>
                      <li>Feature in the programme and a physical copy to keep</li>
                      <li>Feature on the big screen</li>
                      <li>Chance to meet the players and manager pre-match and get something signed (subject to manager’s permission)</li>
                      <li>A group pitchside photo with the other mascots</li>
                      <li>The opportunity to walk out with a player of their choice</li>
                      <li>A photo in the centre circle with the team captains and match officials</li>
                    </ul>
                  </div>
                  <div className='col-md-6 col-sm-6 col-lg-6 prize-list'>
                    <h4 className='mb-4'><span className='title-prizes'>Player Experience Mascots</span> – 9 places, runners up</h4>
                      <ul className='prize-data'>
                        <li>Two match tickets (one for the mascot and one for an accompanying adult)</li>
                        <li>Feature in the programme and a physical copy to keep</li>
                        <li>Feature on the big screen</li>
                        <li>A group pitchside photo with the other mascots</li>
                        <li>The opportunity to walk out with a first team player</li>
                      </ul>
                  </div>
                </div> */}
          <div className="row">
            <div className="col-lg-5">
              <div className="d-flex mb-3">
                <img src={require("../images/200_Club.png")} style={{ width: "150px" }} />
                <h2 className="display-4 text-white ps-3">AFC Totton 200 Club</h2>
              </div>
              <h3 className="text-white pb-5">Building better lives through sport!</h3>
              <div className="description-data py-5" style={{ position: "relative" }}>
                <p className="fs-5 text-dark">
                  By purchasing tickets for AFC Totton 200 Monthly Draw on Play Fund Win, you're directly supporting a your football club. Not just the first team but the whole
                  club. AFC Totton Youth, Walking football, Dimentions, Girls/ladies football along with Weetabix Wildcats and Squad Girls, all of which enable participants from
                  all demographics in the local community to engage in sport.
                </p>
                <p className="fs-5 text-dark">
                  Half of the net proceeds go directly to AFC Totton, providing vital funds for training, equipment, and development programs. Your contribution plays a crucial
                  role in helping the club provide quality opportunities for everyone.
                </p>
                <p className="fs-5 text-dark">
                  But that's not all – you also have the chance to win exciting prizes! The other half of the proceeds is allocated to lucky winners like you. With Play Fund Win's
                  user-friendly online platform, purchasing tickets is convenient and hassle-free.
                </p>
                <p className="fs-5 text-dark">
                  Why wait? Support the Stags by becoming a member of AFC Totton 200 club today by subscribing to a monthly ticket of £10. Your participation not only helps the
                  club but also gives you the opportunity to win amazing prizes. Your contribution can make a real difference in shaping the future of  AFC Totton your club.
                </p>
                {/* <p className='fs-5 text-dark'>With a strong sense of camaraderie and a shared commitment to giving back, the AFC Totton 200 Club is poised to continue expanding their charitable endeavors and making a lasting impact on countless lives. Their unwavering dedication to using their talents and resources for the greater good is a testament to the power of community and the potential of social media as a force for good.</p> */}
                {/* <p className='fs-5 text-dark'>Thank you for your support</p> */}
              </div>
            </div>
            <div className="col-lg-7">
              <img className="mx-auto rounded-3" src={require("../images/Leon_Maloney.jpg")} style={{ width: "100%", height: "90%", objectFit: "cover" }} />
              {/* <source src="{require('../images/NABS_Martins_Story.mp4')}" type="video/mp4"/>  */}
              {/* <iframe width="100%" allow="fullscreen" frameBorder="0" controls controlsList="nodownload" height="100%" src={require('../images/NABS_Martins_Story.mp4')}></iframe>  */}

              {/* <div > */}
              {/* <video className='position-relative rounded-4' controls width="100%" poster={require('../images/martin.png')}>
              <source src={require('../images/NABS_Martins_Story.mp4')}
                      type="video/mp4"/>
                    
                      
              Sorry, your browser doesn't support embedded videos.
          </video> */}
              {/* <div className="playbutton" style={{height : 72,width:72,left: '50%',top : '50%',marginLeft : '-36px', marginTop : '-36px',position : 'absolute', backgroundImage : `url(${require('../images/playvideo.png')})`, backgroundRepeat : 'no-repeat', backgroundSize : '100%'}}></div> */}

              {/* </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="banners-bees">
        <div className="row ">
          <div className="col-lg-6 p-0 m-0">
            <img
              className="mx-auto"
              src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/6a5ce405-b2a2-4bff-a2e3-0cbda076a75d-fans.jpg"
              style={{ width: "100%", height: "100%", objectFit: "Cover", objectPosition: "top" }}
            />
          </div>
          <div className="col-lg-6 p-0 m-0">
            <img className="mx-auto" src={require("../images/AFC_Totton_Squad.jpg")} style={{ width: "100%", height: "100%", objectFit: "Cover", objectPosition: "top" }} />
          </div>
        </div>
      </section>

      {/* <section className='how-it-works_section font-face-sh bg-position py-5' style={{ backgroundImage: `url(${Bg})`, borderRadius: 10 }}>
        <div className='container-2 bg-white p-5 shadow-lg'>
          <div className='how-it-works_title text-center'>
            <h2 className='black-clr display-5 text-uppercase text-center border-bottom mb-5 mt-2 pb-2 d-inline-block font-face-sh-bold'>How It Works</h2>
          </div>
          <div className='row'>
            <div className='col-md-4 text-center'>
              <img src={SignUp} alt="signup" />
              <h2 className='mt-2'><span className='play-icon position-relative'>Play</span></h2>
              <p className='branding-2 fs-5 text-center lh-1 mt-1'>Set up a monthly donation online and be entered into every draw.</p>
            </div>
            <div className='col-md-4 text-center'>
              <img src={Charity} alt="Charity" />
              <h2 className='mt-2'><span className='fund-icon position-relative'>Fund</span></h2>
              <p className='branding-2 fs-5 text-center lh-1 mt-1'>Become a supporter of AFC Totton 200 Club’s community work.</p>
            </div>
            <div className='col-md-4 text-center'>
              <img src={Trophy} alt="Trophy" />
              <h2 className='mt-2'><span className='win-icon position-relative'>Win</span></h2>
              <p className='branding-2 fs-5 text-center lh-1 mt-1'>Stay tuned for half-time when winners are informed via email!</p>
            </div>
           
          </div>
        </div>



      </section> */}

      <section className="how-it-works_section font-face-sh bg-position py-5 bg-branding-1" style={{ marginTop: -10 }}>
        <div className="container p-5">
          <div className="how-it-works_title text-center">
            <h2 className="display-5 text-center mb-5 mt-2 pb-2 d-inline-block font-face-sh-bold text-white">How It Works</h2>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                <img src={SignUp} alt="signup" />
                <h2 className="mt-2 mx-2">
                  <span className="position-relative">Play</span>
                </h2>
              </div>

              <p className="black-clr fs-5 text-center lh-1 mt-1">AFC Totton 200 Club Draw</p>
            </div>
            <div className="col-md-4 text-center">
              <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                <img src={Charity} alt="Charity" />
                <h2 className="mt-2 mx-2">
                  <span className="position-relative">Fund</span>
                </h2>
              </div>

              <p className="black-clr fs-5 text-center lh-1 mt-1">Become a supporter of AFC Totton 200 Club work.</p>
            </div>
            <div className="col-md-4 text-center">
              <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                <img src={Trophy} alt="Trophy" />
                <h2 className="mt-2 mx-2">
                  <span className="position-relative">Win</span>
                </h2>
              </div>
              <p className="black-clr fs-5 text-center lh-1 mt-1">Winners will be announced at the end of the month of the draw</p>
            </div>
          </div>
          <div className="how-it-works-button text-center pt-4">
            {login ? (
              <Link to={"/subscription-plan"} className="btn bg-branding-2 border-0 w-auto rounded-pill py-3  px-4 mt-1 lh-1 desktop-btn" style={{ color: "#fff" }}>
                Find out more
              </Link>
            ) : (
              <Link to={"/signup"} className="btn bg-branding-2 border-0 w-auto rounded-pill py-3  px-4 mt-1 lh-1 desktop-btn" style={{ color: "#fff" }}>
                Find out more
              </Link>
            )}
          </div>
        </div>
      </section>

      {/* <section className='charity_section font-face-sh mb-5 pb-5'>
        <div className='container-2'>
          <div className='charity_title text-center py-5 px-md-5'>
            <h2 className='black-clr display-4 text-uppercase text-center px-md-5 lh-1 font-face-sh-bold'>AFC Totton 200 Club</h2>
            <p className='branding-2 fs-5 lh-1 mt-4 w-50 m-auto'>Building better lives through sport</p>
            <p className='black-clr fs-6 lh-1 mt-5'>Read more here</p>
            <div style={{ marginTop: -35 }}>
              <img src={Arrow} alt="Arrow" />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-6 pe-5'>
              <p className='branding-3 fs-5 lh-2'>AFC Totton 200 Club is the Official Charity of AFC Totton 200 Club Football Club, committed to our vision for a healthy, inclusive and thriving community.</p>
              <p className='branding-3 fs-5 lh-2'>With the passionate pursuit of building better lives through sport, we connect AFC Totton 200 Club to young people and adults across South West London. We work with a broad range of the community, supporting disabled young people, refugees, socially isolated adults, people with mental health challenges and many others.</p>
              <p className='branding-3 fs-5 lh-2'>We change the lives of over 10,000 people every year. We inspire and engage through sport, improving the physical health and mental wellbeing of our participants, while supporting people across our community to feel socially connected and confident.</p>
              <p className='branding-3 fs-5 lh-2'>Help maintain these impactful projects and become a Foundation Supporter today, check out the links below to find out more.</p>
              <Link to={'/signup'} className="btn bg-branding-btn shadow-lg text-white border-0 w-75 mb-5 rounded-pill py-3 mt-5 lh-1">Find out more</Link>
              <br />
              <Link to={'/signup'} className="btn bg-branding-btn shadow-lg text-white border-0 w-75 mb-5 rounded-pill py-3 lh-1">Support us</Link>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-6'>
              <div className='row'>
                <div className='col-sm-6 col-md-12 col-lg-6 col-6 pe-4'>
                  <img className='w-100 rounded-3' src={require('../images/charity/image1.jpg')} alt="charity-image1" />
                </div>
                <div className='col-sm-6 col-md-12 col-lg-6 col-6 ps-4'>
                  <img className='w-100 rounded-3' src={require('../images/charity/image2.jpg')} alt="charity-image2" />
                </div>
              </div>
              <div className='row pt-4 mt-3'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <img className='w-100 rounded-3' src={require('../images/charity/image3.jpg')} alt="charity-image3" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section> */}

      <section>
        {/* <div className='draw-entry position-fixed px-3 py-3 bottom-0 bg-branding-1 w-100' style={{zIndex : 9999999 }}> */}

        {!paymentDetails && SubscriptionPlan ? (
          <section className="subscription-plan-home font-face-sh draw-entry position-fixed px-3 py-3 bottom-0 bg-branding-1 w-100" style={{ zIndex: 999 }}>
            <div className="row align-items-center">
              <div className="col-sm-9 col-md-9 col-lg-9 col-9">
                <h1 className="text-light">Enter the draw</h1>
                <p className="text-light">How many tickets would you like to buy?</p>
              </div>
              <div
                className="col-sm-3 col-md-3 col-lg-3 col-3 text-center border-top bg-white"
                style={{ backgroundColor: "#000", borderBottomLeftRadius: 70, borderBottomRightRadius: 70, padding: "14px 0px", marginTop: "-23px" }}
              >
                <h4 className="text-dark">Total</h4>
                <h4 className="text-light d-flex justify-content-center align-items-center">
                  <div className="subscription-plan-price_currency branding-1 fs-2 font-face-sh-bold mt-4 me-1">£</div>
                  <div className="subscription-plan-price_amount branding-1 display-3 font-face-sh-bold">{amount}</div>
                </h4>
              </div>
            </div>
            <div className="subscription-plan-container mx-auto" style={{ maxWidth: 700 }}>
              <div
                className={Freemodal ? "steps-subscription-plan px-0 position-relative pt-5 d-none" : "steps-subscription-plan px-0 position-relative d-block"}
                style={{ zIndex: 999 }}
              >
                <div className="ticket-entry">
                  <div className="form-check form-switch">
                    <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                    <label className="form-check-label text-white" htmlFor="flexSwitchCheckDefault">
                      {!isoneoff ? "Tick to make a one-off entry into the next draw" : "untick to make a Subscription entry into the next draw"}
                    </label>
                  </div>

                  {/* <div className='terms-cond'>
          <p className='m-0 text-white'>Alternative entry routes available, <Link className='text-decoration-none branding-2 branding-2-hover' to={'/terms-conditions'}>click here</Link> for terms.</p>
      </div> */}

                  <div className="row mt-3">
                    <div className="col-sm-8 col-md-8 col-lg-8 col-8">
                      <div className="step-subscription-plan-selector home-data">
                        <ul className="d-flex unstyled list-unstyled gap-3">
                          <li className={isActive1 ? "active" : ""} onClick={() => handleClick("first", 10, 1)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£10</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>1
                            </strong>
                          </li>
                          <li className={isActive2 ? "position-relative active" : "position-relative"} onClick={() => handleClick("second", 20, 2)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£20</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>2
                            </strong>
                          </li>
                          <li className={isActive3 ? "active" : ""} onClick={() => handleClick("third", 50, 5)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£50</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>5
                            </strong>
                          </li>
                          <li className={isActive4 ? "active" : ""} onClick={() => handleClick("fourth", 100, 10)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£100</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>
                              10
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4 col-4">
                      <div className="step-subscription-button text-center d-flex justify-content-center">
                        <button
                          onClick={() => toPaymentDetails(true)}
                          className="btn bg-branding-2 border-0 rounded-pill py-2 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                          style={{ width: 400, height: 48 }}
                        >
                          <span>{!isoneoff ? "Subscribe" : "Buy Now"}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Freemodal ? "free-entries-modal d-block position-relative" : "free-entries-modal d-none"}>
                <div className="free-entry-modal_title_block mb-5 pb-5">
                  <img
                    className="cross bg-white rounded-3 p-1 position-absolute end-0 top-0"
                    src={Cross}
                    style={{ width: 56, boxShadow: "0 4px 16px rgb(20 19 38 / 30%)", cursor: "pointer" }}
                    onClick={() => setFreemodal(!Freemodal)}
                  />
                  <h2 className="free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5">Free Prize Draw</h2>
                  <p className="free-entry-modal_text text-light text-center fs-5 mx-auto mt-5" style={{ maxWidth: 375 }}>
                    While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.
                  </p>
                </div>
                <div className="free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex" style={{ maxWidth: 375 }}>
                  <div className="free-entry-icon">
                    <img src={FreeEntryIcon} style={{ width: 24 }} />
                  </div>
                  <div className="free-entry-description ps-4 fs-14 lh-1">
                    <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                    <p>
                      * First Name
                      <br />
                      * Last Name
                      <br />* Email
                    </p>
                    <p>
                      to: PFW Holdings Ltd
                      <br />
                      Play Fund Win Office #300,
                      <br />4 Blenheim Court,
                      <br />
                      Peppercorn Close,
                      <br />
                      Peterborough,
                      <br />
                      PE1 2DU
                      <br />
                      <br />
                      You will be entered into the prize draws for the month in which we receive your post entries.
                      <br />
                      <br />
                      Note: This will not result in any contributions to the Foundation
                    </p>
                    <div className="emoji-icon text-center pe-5 mt-4 pt-2">
                      <img className="mx-auto" src={require("../images/emoji_icon.png")} style={{ width: 66 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : paymentDetails && !oneShow ? (
          <section
            className="personal-details font-face-sh py-5 position-fixed"
            style={{ backgroundColor: "#fff", left: 0, right: 0, top: 0, bottom: 0, zIndex: 999999, overflow: "scroll" }}
          >
            <a className="black-clr text-decoration-none" href="/">
              <img className="mx-3" src={require("../images/back.png")} style={{ width: 32 }} />
            </a>
            <div className="subscription-plan-container mx-auto" style={{ maxWidth: 780 }}>
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 step-user-details pe-5">
                  <div className="step-plan_step branding-1 rounded-pill text-center mx-auto" style={{ backgroundColor: "#f7f7fd", width: 112, height: 45, lineHeight: "45px" }}>
                    <strong>Step 2 of 2</strong>
                  </div>
                  {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                  <p className="text-center" style={{ color: "#808080", fontSize: "18px" }}>
                    {isoneoff ? "" : "We’ll take your amount "}
                  </p>
                  <form method="post">
                    {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                    {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                    {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                    <div className="form-checkbox">
                      <ul className="checkbox-list list-unstyled">
                        <li className="checkbox-item mb-4">
                          <label className="input-checkbox d-flex">
                            <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, I’d like to receive updates about AFC Totton 200 Club via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label className="input-checkbox d-flex">
                            <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, AFC Totton 200 Club can contact me about their work via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label
                            className="step-payment-details_consent_description mt-3 input-checkbox d-flex"
                            style={{ backgroundColor: "rgb(198,29,35,.1)", color: "#302d51", padding: "14px 18px", borderRadius: 20, fontSize: 13 }}
                          >
                            <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span>
                              By clicking on Confirm you agree to AFC Totton 200 Club{" "}
                              <Link to={"/terms-conditions"} style={{ color: "#302d51" }}>
                                Terms & Conditions of Use
                              </Link>
                              ,{" "}
                              <Link to={"/privacy-policy"} style={{ color: "#302d51" }}>
                                Privacy Policy
                              </Link>{" "}
                              and that you <strong>are at least 18 years old.</strong>
                            </span>
                          </label>
                        </li>
                        <li className="checkbox-item pt-4 pb-0">
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(event) => setFullName(event.target.value)}
                            className="input mb-3"
                          />
                          {/* <input type="text" id="firstname" value={userFirstName} onChange={(event) => setFirstName(event.target.value)} placeholder="Enter first name" className="input" style={{ display: 'block' }} />
                                                <input type="text" id="lastname" value={userLastName} onChange={(event) => setLastName(event.target.value)} placeholder="Enter last name" className="input" style={{ display: 'block' }} /> */}
                          <input
                            type="email"
                            id="email"
                            value={userEmail}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder="Email address"
                            className="input"
                            style={{ display: "block" }}
                          />
                          {/* <input type="tel" value={dob} onInput={(event) => dateSet(event.target.value, event)} name="dateOfBirth" id="dateOfBirth" placeholder="Date of birth (DD/MM/YYYY)" className="input mb-2" /> */}
                          {/* <div className='dob-field'>
                                                      <input type="date" id={"donb"} value={dob} onFocus={(e)=> {e.currentTarget.type = "date";e.currentTarget.focus();}} onInput={(event) => { setDob(event.target.value, event); dateSet(event.target.value, event)}} name="dateOfBirth" className='input mb-2' /> 
                                                      <label className='mb-2' htmlFor='donb' style={{ color: '#818d9d', fontSize: 16 }}>{dob?dob:"Date of birth (DD/MM/YYYY)"}</label>
                                                    </div> */}
                          <div className="form-radio-box mb-4">
                            <label className="mb-2" style={{ color: "#808080", fontSize: 14 }}>
                              Gender
                            </label>
                            <ul className="radio-list list-unstyled d-flex gap-3">
                              <li className="radio-item">
                                <label className="input-radio">
                                  <input type="radio" name="gender" value="1" defaultChecked onClick={() => setGender(1)} />
                                  <span className="input-radio-label">Male</span>
                                </label>
                              </li>
                              <li className="radio-item">
                                <label className="input-radio">
                                  <input type="radio" name="gender" value="2" onClick={() => setGender(2)} />
                                  <span className="input-radio-label">Female</span>
                                </label>
                              </li>
                              <li className="radio-item">
                                <label className="input-radio">
                                  <input type="radio" name="gender" value="3" onClick={() => setGender(3)} />
                                  <span className="input-radio-label">Other</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* <p className='step-payment-details_consent_description mt-5' style={{ backgroundColor: 'rgb(198,29,35,.1)', color: '#302d51', padding: '14px 18px', borderRadius: 20, fontSize: 13 }}>By clicking on Confirm you agree to AFC Totton 200 Club <Link to={"/terms-conditions"} style={{ color: '#302d51' }}>Terms & Conditions of Use</Link>, <Link to={"/privacy-policy"} style={{ color: '#302d51' }}>Privacy Policy</Link> and that you <strong>are at least 18 years old.</strong></p> */}
                    <div className="step-subscription-button text-center mb-3 d-flex justify-content-center"></div>
                  </form>
                  <button
                    onClick={checkConsents}
                    className="btn bg-branding-green border-0 rounded-pill py-2 mt-3 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: 48 }}
                  >
                    Pay now
                  </button>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0">
                  <div className="user-details-info bg-branding-1 rounded-4" style={{ padding: "35px 35px 19px", boxShadow: "0 15px 50px -30px #000000" }}>
                    <h3 className="text-light fs-5 mb-4">Summary</h3>
                    <div className="summary-info-impact pb-3">
                      <div className="summary-info-impact_title text-light d-flex align-items-center" style={{ fontSize: 16 }}>
                        1x VIP Prizes Up For Grabs!
                      </div>
                      {/* <div className='summary-info-impact_period lh-1' style={{ color: 'rgb(255,255,255,0.7)', paddingLeft: 35 }}>every draw</div> */}
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    <div className="summary-info-price d-flex align-items-center pb-2">
                      <span className="summary-info-price_amount text-light display-4">£ {amount}</span>
                      <span className="summary-info-price_period ps-3" style={{ color: "rgb(255,255,255,0.7)" }}>
                        {isoneoff ? "" : "per month"}
                      </span>
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    <p className="summary-info-upgrade-text m-0" style={{ color: "rgb(255,255,255,0.7)" }}>
                      Upgrade or cancel anytime
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {isStripe ? (
          <Modal show={oneShow} onHide={() => setOneShow(false)} size="md" centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Elements stripe={stripePromise}>
                <StripeForm
                  UserID={UserID}
                  oneoff={isoneoff}
                  show={true}
                  email={userEmail}
                  name={fullName}
                  address1="-"
                  address2="-"
                  postcode="-"
                  amount={amount}
                  entries={entries}
                  hideModal={hideModal}
                  navigate={navigate}
                  timeout={timeout}
                />
              </Elements>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
        <ToastContainer />

        {/* </div> */}
      </section>

      <footer className="font-face-sh mt-5 position-relative home-footer">
        <div className="container">
          <div className="footer-logo">
            <img className="" src={LogoFoundationGray} alt="logo-foundation-gray" style={{ width: 100 }} />
          </div>
          <div className="row pt-4">
            <div className="col-sm-12 col-md-12 col-lg-5 black-clr">
              <h4 className="mb-4 pb-2 pt-2 font-face-sh-bold">Contact us</h4>
              <div className="footer-address d-flex align-items-start">
                <img className="me-3" src={FooterIconAddress} alt="FooterIconAddress" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>Post: PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU</p>
              </div>
              <div className="footer-email d-flex align-items-start">
                <img className="me-3" src={FooterIconEmail} alt="FooterIconEmail" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>
                  Support:{" "}
                  <a href="mailto:hello@playfundwin.com" className="text-decoration-none black-clr branding-1-hover">
                    hello@playfundwin.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 mt-md-5">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 black-clr mb-3">
                  <p className="mt-4 pt-2 font-face-sh-bold">Follow us</p>
                  <div className="d-flex">
                    <a className="branding-2 text-decoration-none" href="https://www.facebook.com/afctottonofficial" target="_blank">
                      <img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} />
                    </a>
                    <a className="branding-2 text-decoration-none" href="https://twitter.com/AFCTotton" target="_blank">
                      <img className="me-3" src={Twitter} alt="Twitter" style={{ width: 24 }} />
                    </a>
                    <a className="branding-2 text-decoration-none" href="https://www.instagram.com/afctottonofficial/" target="_blank">
                      <img className="me-3" src={Instagram} alt="Instagram" style={{ width: 24 }} />
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex mt-md-5 pt-2">
                    <img className="me-3" src={Visa} alt="Visa" style={{ width: 64 }} />
                    <img className="me-3" src={Mastercard} alt="Mastercard" style={{ width: 64 }} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4  mt-md-5 black-clr ps-md-5">
              <p className="mt-4 pt-3" style={{ fontSize: 13 }}>
                AFC Totton 200 Club is operated by{" "}
                <a className="text-decoration-none black-clr branding-1-hover" href="https://playfundwin.com/" target="_blank">
                  Play Fund Win
                </a>
              </p>
              <div className="d-flex" style={{ fontSize: 14 }}>
                <Link className="black-clr text-decoration-none pe-5 branding-1-hover font-face-sh-bold" to={"/terms-conditions"}>
                  Terms & Conditions
                </Link>
                <Link className="black-clr text-decoration-none branding-1-hover font-face-sh-bold" to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
