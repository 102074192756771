import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import TrophyIcon from "../images/trophy-icon.svg";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import axios from "axios";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export default function PrizeSlider() {
  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  const [scroll, setScroll] = useState(false);
  const [DrawData, setDrawData] = useState([]);
  const [PrizeData, setPrizeData] = useState([]);
  const parser = new DOMParser();
  const [totalQuantities, setTotalQuantities] = useState(0);

  const [open, setOpen] = useState(false);
  const [two, setTwo] = useState(false);
  const [Three, setThree] = useState(false);
  const [four, setFour] = useState(false);

  const processedData = PrizeData && PrizeData.length === 3 ? PrizeData.concat(PrizeData) : PrizeData;
  const slidesToShow = (PrizeData && PrizeData.length <= 3) ? PrizeData.length : 3;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
    // cssEase: "linear"
  };

  function calculateTotalQuantity(PrizeData) {
    let sum = 0;
    {
      PrizeData.forEach((item) => {
        sum += item.prizeQuantity;
      });
    }
    return sum;
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
    axios.get(APIURL + "/sites/" + siteId + "/draws").then((res) => {
      let alldraw = res.data.content;
      let activeDraw = res.data.content.filter(
        (item) => item.drawIsClosed != 1
      );
      let selectedDraw = {};
      if (activeDraw.length > 0) {
        selectedDraw = activeDraw[0];
      } else {
        selectedDraw = alldraw[0];
      }
      setDrawData(selectedDraw);
    });
  }, []);

  useEffect(() => {
    axios
      .get(APIURL + `/draws/${DrawData?.drawID}/prizes`, {
        withCredentials: true,
        credentials: "same-origin",
      })
      .then((res) => {
        setPrizeData(res?.data?.content);
        setTotalQuantities(calculateTotalQuantity(res?.data?.content));
      });
  }, [DrawData]);

  return (
    <div className="prize-block-inner px-0 py-5 pt-0">
      <Slider {...settings}>
        {processedData?.map((item) => {
          return (
            <div className={`prize-block prize-${item.prizeID}`}>
              <div className="rounded-3 border m-2">
                <div
                  className="prize-photo col-sm-12 col-md-12 col-lg-12 text-center p-5 position-relative"
                  style={{
                    height: 250,
                    backgroundImage: `url(${item.prizeImageURL})`,
                    backgroundSize: "cover",
                    borderTopLeftRadius : 12,
                    borderTopRightRadius: 12,
                    backgroundPosition: "top",
                  }}
                >
                  {/* <img className='w-25 position-absolute bg-white' style={{left: 0,bottom: "-1px",padding : 10, height: "100px", objectFit: "contain"}} src={require('../images/Thinkbox.png')} alt="Thinkbox"/> */}
                  {/* <span className="display-1 text-white"></span> */}
                </div>
                <div
                  className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12"
                  style={{
                    borderBottomLeftRadius: 12,
                    borderBottomRightRadius: 12,
                  }}
                >
                  <h4
                    className="branding-1 font-face-sh-bold"
                    dangerouslySetInnerHTML={{
                      __html: parser.parseFromString(
                        `${item.prizeName}`,
                        "text/html"
                      ).body.textContent,
                    }}
                  />
                  {item.prizeDescription && item.prizeDescription !== '-'? 
                  <>
                    <Collapse in={open}>
                      <div
                        id="example-collapse-text" 
                        className={!open ? "collapse" : "active"}
                      >
                        
                        <div dangerouslySetInnerHTML={{ __html: item.prizeDescription }}></div>
                      </div>
                    </Collapse>
                    <Button
                      className="btn bg-branding-2 border-0 rounded-pill py-2 mt-0 lh-2"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      {open ? "Hide" : "What's Included"}
                    </Button>
                  </>
                  : "" }
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
